import ContentfulImage from '@/components/contentful-image';
import Footer from '@/components/footer';
import Header from '@/components/header';
import HeadC from '@/ctf-components/headc/headc';
import { fetchFooterData } from '@/hooks/useFooter';
import { fetchHeaderMainMenuNavigation } from '@/hooks/useNavigation';
import RobotImage from '@/public/icons/404.svg';
import { QueryClient, dehydrate } from '@tanstack/react-query';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';

const FourOfFour = () => {
  const { t } = useTranslation();
  // SEO should be read from Contentful
  const seo = {
    title: 'Page not found | Consulteer — Your one stop digital agency',
    description:
      'Consulteer is about smart product problem solving for tomorrow relevant businesses.',
    canonicalUrl: 'https://www.consulteer.com/',
    nextPage: 'https://www.consulteer.com/',
    nofollow: false,
    noindex: false,
    image: {
      url: 'https://images.ctfassets.net/mr98g2zcqin4/3d0B6KIpFZWe4K4LB0pfDc/5f9cce3952a6527f4e5c7dfab2c747df/consulteer-home.png',
      width: 1200,
      height: 630,
      title: 'Page not found | Consulteer — Your one stop digital agency',
      description:
        'Consulteer is about smart product problem solving for tomorrow relevant businesses.'
    },
    ldType: 'Page',
    ldAuthor: 'Consulteer team',
    ldAuthorUrl: 'https://www.consulteer.com/',
    ldPublished: '',
    ldUpdated: ''
  };

  return (
    <>
      <HeadC seo={seo} />
      <Header />
      <main className="container mx-auto">
        <div className="flex flex-col justify-center items-center text-center mt-10 lg:mt-20">
          <div>
            <ContentfulImage src={RobotImage} />
          </div>
          <div className="mt-10 lg:mt-12">
            <h1 className="text-8xl">{t('404.title')}</h1>
            <p className="uppercase font-bold mt-5">{t('404.description')}</p>
          </div>
          <div>
            <Link
              className="green-button mt-5 lg:mt-10 px-6 py-3 ml-2"
              href="/"
            >
              {t('404.button')}
            </Link>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export async function getStaticProps({ locale }: { locale: string }) {
  const queryClient = new QueryClient();
  await queryClient.prefetchQuery({
    queryKey: ['footer', locale],
    queryFn: () => fetchFooterData(locale)
  });
  await queryClient.prefetchQuery({
    queryKey: ['headermainmenu', locale],
    queryFn: () => fetchHeaderMainMenuNavigation(locale)
  });

  return {
    props: {
      dehydratedState: dehydrate(queryClient)
    },
    revalidate: 10
  };
}

export default FourOfFour;
